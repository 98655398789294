import React, { useState, useRef, useEffect } from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/layout/seo';
import Container from '../components/layout/container';
import Uploader from '../components/forms/uploader';
import Layout from '../containers/layout';
import sanityClient from '../utils/sanity_client';
import PQueue from 'p-queue';
import slugify from 'slugify';
import { Vimeo } from 'vimeo';

import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAlert from '@material-ui/lab/Alert';
import LinearProgress from '@material-ui/core/LinearProgress';
import Modal from '@material-ui/core/Modal';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import Zoom from '@material-ui/core/Zoom';
import { capitalize } from '../lib/helpers';
import { isAuthenticated, login } from '../utils/auth';
import { PORTFOLIO_FILE_TYPES } from '../lib/constants';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#252525',
    },
    secondary: {
      main: '#dd271a',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  form: {
    marginBottom: '6rem',
    '& > * + *': {
      marginTop: '2rem',
    },
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    backgroundColor: 'white',
  },
  formControl: {
    minWidth: 120,
  },
  label: {
    marginLeft: theme.spacing(1.5),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  h3: {
    fontSize: '9px',
  },
}));

// todo: for some reason these aren't pulled in zeit in env vars or secrets?
const vimeoClient = new Vimeo(
  process.env.VIMEO_CLIENT_ID || '82fd36261c108676139b081196264081b93180f3',
  process.env.VIMEO_CLIENT_SECRET ||
    'xhW7cqoU+Fvrrd9pXaCuU9UR26R0Wh/SPMOjjGOII8pbwmQ+ZA2hcNPAE0fSKADGFRo0u4o5oNlQPQTQv88p6cCugF3STemYSeX0gj/MqDlqMx9AsQmeta6KFyvo5bpL',
  process.env.VIMEO_APP_API_KEY || '4f926148e2744b0f07f092e89a1b4083'
);

const fileQueue = new PQueue({
  concurrency: 4,
  interval: 1000 / 25,
});

const StudentProfileForm = (props) => {
  /*  don't require login in development or preview mode */
  // if (
  //  typeof window !== 'undefined' &&
  //  window.location.hostname !== 'aa-pcae-showcase-web-fall-2021.vercel.app' &&
  //  window.location.hostname !== 'pcae.academyart.edu'
  // ) {
  //  console.log('Skipping login requirement in development/staging mode');
  // } else {
  if (!isAuthenticated()) {
    login();
    return <p>Redirecting to login...</p>;
  }
  // }

  const classes = useStyles();

  const { schools, headerBackgroundImage } = props.data;
  const [submitting, setSubmitting] = useState(false);
  const [hasBeenSubmit, setHasBeenSubmit] = useState(false);
  const [uploadMessage, setUploadMessage] = useState('Uploading files');
  const [submitStep, setSubmitStep] = useState(null);
  const [completed, setCompleted] = useState(0);
  const [buffer, setBuffer] = useState(10);
  const [formErrors, setFormErrors] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [fileUploadStatus, setFileUploadStatus] = useState(null);
  const [school, setSchool] = useState(null);
  const [majors, setMajors] = useState([]);
  const [major, setMajor] = useState(null);
  const [modalStyle] = useState(getModalStyle);
  const [modalOpen, setModalOpen] = useState(false);
  const [nameError, setNameError] = useState(null);
  const [idError, setIdError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [modality, setModality] = useState(null);

  const nameRef = useRef(null);
  const idRef = useRef(null);
  const emailRef = useRef(null);

  useEffect(() => {
    if (majors?.length === 1) {
      setMajor(majors[0]);
    }
  }, [majors]);

  const steps = [uploadMessage, 'Submitting profile', 'Complete'];

  function chooseModality(e) {
    let { value } = e.target;
    if (!value) return;
    setModality(value);
  }

  function chooseSchool(e) {
    let { value } = e.target;
    if (!value) return;
    const school = schools.nodes.find((school) => school._id === value);
    setSchool(school);
    setMajors(school.class);
  }

  function chooseMajor(e) {
    let { value } = e.target;
    const major = majors.find((major) => major._id === value);
    setMajor(major);
  }

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      height: '300px',
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const updateFileUploadsCallback = (media, type = 'added') => {
    if (type === 'removed') {
      const newFiles = filesToUpload.filter(({ meta }) => meta.name !== media);

      setFilesToUpload(newFiles);
      return;
    }

    if (media && media.length) {
      setFilesToUpload(media);
    }
  };

  function updateTextField(event, type, typing = false) {
    const value = event.target.value;

    switch (type) {
      case 'name':
        if (value.length < 4) {
          if (typing) return;
          setNameError('Name must be 4 characters or longer!');
        } else {
          setNameError(null);
        }
        break;
      case 'id':
        // eslint-disable-next-line no-case-declarations
        const idTest = /^([0][0-9]{7})$/.test(value);
        if (!idTest) {
          if (typing) return;
          setIdError('Enter a valid 8 character student ID starting with 0');
        } else {
          setIdError(null);
        }
        break;
      case 'email':
        // eslint-disable-next-line no-case-declarations
        const emailTest = /^([a-zA-Z0-9_\-\\.]+)@([a-zA-Z0-9_\-\\.]+)\.([a-zA-Z]{2,5})$/.test(value);
        if (!emailTest) {
          if (typing) return;
          setEmailError('Enter a valid email address!');
        } else {
          setEmailError(null);
        }
        break;
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    let localFormErrors = [];

    if (idError || emailError || nameError) {
      if (idError) {
        idRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else if (emailError) {
        emailRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else if (nameError) {
        nameRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
      return;
    }

    setSubmitting(true);
    setHasBeenSubmit(true);
    setModalOpen(true);

    let profileForm = document.getElementById('profileForm');
    const FD = new FormData(profileForm);

    let uploadedFiles = [];
    let error = false;

    const filesUpload = [...filesToUpload];

    if (filesUpload.length > 0) {
      setSubmitStep(0);
      setUploadMessage(`Submitting files (0/${filesUpload.length})`);
      let progress = 0;

      filesUpload.forEach((f, idx) => {
        setCompleted(0);
        setCustomMeta(FD, f);
        fileQueue.add(async () => {
          setFileUploadStatus({ [f.meta.id]: 'uploading' });
          progress += 1;
          setUploadMessage(`Submitting files (${progress}/${filesUpload.length})`);

          const metaType = f.meta.type.includes('video') ? 'video' : f.meta.type;

          switch (metaType) {
            case 'video':
              await uploadToVimeo(f)
                .then(({ uri, url }) => {
                  setFileUploadStatus({ [f.meta.id]: 'complete' });
                  uploadedFiles.push(prepareSanityObject(FD.get('id'), url, 'video', f, idx === 0));
                })
                .catch((e) => {
                  console.error('ERROR uploading video:', e);
                  localFormErrors.push(
                    `There was an error uploading your video ${f.meta.name} please try a smaller file or a faster internet connection`
                  );
                  error = true;
                  setFileUploadStatus({ [f.meta.id]: 'failed' });
                  setSubmitting(false);
                });
              break;
            case 'application/pdf':
              await uploadToSanity(f, 'file')
                .then(({ id, type }) => {
                  setFileUploadStatus({ [f.meta.id]: 'complete' });
                  uploadedFiles.push(prepareSanityObject(FD.get('id'), id, type, f, idx === 0));
                })
                .catch((e) => {
                  console.error('ERROR uploading pdf:', e);
                  localFormErrors.push(
                    `There was an error uploading your file ${f.meta.name} please try a smaller file or a faster internet connection`
                  );
                  error = true;
                  setFileUploadStatus({ [f.meta.id]: 'failed' });
                  setSubmitting(false);
                });
              break;
            default:
              await uploadToSanity(f, 'image')
                .then(({ id, type }) => {
                  setFileUploadStatus({ [f.meta.id]: 'complete' });
                  uploadedFiles.push(prepareSanityObject(FD.get('id'), id, type, f, idx === 0));
                })
                .catch((e) => {
                  console.error('ERROR uploading image:', e);
                  localFormErrors.push(
                    `There was an error uploading your image ${f.meta.name} please try a smaller file or a faster internet connection`
                  );
                  error = true;
                  setFileUploadStatus({ [f.meta.id]: 'failed' });
                  setSubmitting(false);
                });
          }
        });
      });
    }

    await fileQueue.onIdle();
    if (error) {
      setFormErrors(localFormErrors);
      setModalOpen(false);
      return;
    }
    setSubmitStep(1);

    const profileID = FD.get('id').replace(/[^\d\w]/gi, '');
    const profileDocument = {
      _id: `drafts.${profileID}`,
      _type: 'student',
      name: FD.get('name'),
      email: FD.get('email'),
      id: profileID,
      modality: FD.get('modality'),
      school: FD.get('schoolId') ? { _type: 'reference', _weak: true, _ref: FD.get('schoolId') } : undefined,
      class: FD.get('majorId') ? { _type: 'reference', _weak: true, _ref: FD.get('majorId') } : undefined,
      slug: {
        _type: 'slug',
        current: slugify(FD.get('name').toLowerCase(), { remove: /[^(A-Za-z0-9\s)]/g }),
        options: {
          source: 'name',
          maxLength: 200,
        },
      },
      portfolio: uploadedFiles && uploadedFiles.length ? uploadedFiles : undefined,
      studentUploaded: true, // flag so we can find all student uploaded forms later
    };

    await sanityClient
      .createOrReplace(profileDocument)
      .then((res) => {
        setSubmitStep(3);
        profileForm.reset();
        window.scrollTo({
          top: 0,
          left: 100,
          behavior: 'smooth',
        });
      })
      .catch((e) => {
        console.error(e);
        setFormErrors([
          ...localFormErrors,
          'Something went wrong with uploading your profile.  Please try again later.',
        ]);
        setSubmitStep(null);
        setModalOpen(false);
        setSubmitting(false);
      })
      .finally(() => {
        setSubmitting(false);
        setModalOpen(false);
      });
  }

  async function uploadToVimeo(f) {
    return new Promise((resolve, reject) => {
      vimeoClient.upload(
        f.file,
        {
          name: f.meta.customMeta.title,
          description: f.meta.customMeta.caption,
        },
        function (uri) {
          vimeoClient.request(
            {
              method: 'PUT',
              path: `/users/109329917/projects/1515209/videos/${uri.split('/')[2]}`,
            },
            function (error) {
              if (error) {
                console.error(error);
              }
            }
          );
          // returns the link
          vimeoClient.request({ path: uri + '?fields=link' }, function (error, body, statusCode, headers) {
            if (error) {
              console.error(error);
              return;
            }
            return resolve({ uri: uri, url: body.link });
          });
        },
        function (bytes_uploaded, bytes_total) {
          var percentage = ((bytes_uploaded / bytes_total) * 100).toFixed(2);
          const diff = Math.random() * 10;
          const diff2 = Math.random() * 10;
          const completed = parseInt(percentage, 10) + diff;
          const buffer = parseInt(completed, 10) + diff + diff2;
          setCompleted(completed);
          setBuffer(buffer);
        },
        function (error) {
          reject(error);
        }
      );
    });
  }

  async function uploadToSanity(f, type) {
    // todo: can we get the actual progress somehow?
    startFakeUploadProgress();
    const r = await sanityClient.assets.upload(type, f.file);
    endFakeUploadProgress();
    return { id: r.document._id, type: r.document.mimeType };
  }

  function startFakeUploadProgress() {
    if (completed > 100) {
      setCompleted(0);
      setBuffer(10);
    } else {
      const diff = Math.random() * 10;
      const diff2 = Math.random() * 10;
      setCompleted(completed + diff);
      setBuffer(completed + diff + diff2);
    }
  }

  function endFakeUploadProgress() {
    setCompleted(100);
  }

  function setCustomMeta(FD, f) {
    const entries = FD.entries();
    const fileId = f.meta.id.split('-')[0];

    let entry = entries.next();
    f.meta.customMeta = {};
    while (!entry.done) {
      const [type, fileMetaId, _, name] = entry.value[0].split('-');
      if (type === 'filemeta' && fileMetaId === fileId && entry.value[1]) {
        // build special object for assetCategory ref value
        if (name === '_category') {
          f.meta.customMeta.assetCategory = {
            _ref: entry.value[1],
            _type: 'reference',
            _weak: true,
          };
        } else if (name === 'description') {
          f.meta.customMeta.description = [
            {
              _type: 'block',
              _key: 'student-' + fileId + '-metadescriptionblock-' + fileId,
              style: 'normal',
              children: [
                {
                  _type: 'span',
                  _key: 'student-' + fileId + '-metadescription-' + fileId,
                  marks: [],
                  text: entry.value[1] || '',
                },
              ],
              markDefs: [],
            },
          ];
        } else {
          f.meta.customMeta[name] = entry.value[1];
        }
      }
      entry = entries.next();
    }
  }

  function prepareSanityObject(studentId, ref, type, f) {
    switch (type) {
      case 'video':
        return {
          _key: 'student-' + studentId.replace(/[^\d\w]/gi, '') + '-profile-video-' + f.meta.id,
          _type: 'video',
          caption: f.meta.caption,
          title: f.meta.customMeta.title || 'profile video',
          url: ref,
          ...f.meta.customMeta,
        };
      case 'image/jpeg':
      case 'image/png':
      case 'image/gif':
        return {
          _key: 'student-' + studentId.replace(/[^\d\w]/gi, '') + '-profile-image-' + f.meta.id,
          _type: 'figure',
          image: { _type: 'image', asset: { _type: 'reference', _ref: ref } },
          alt: f.meta.customMeta.caption || f.meta.customMeta.title || 'profile image',
          ...f.meta.customMeta,
        };
      default:
        return {
          _key: 'student-' + studentId.replace(/[^\d\w]/gi, '') + '-profile-file-' + f.meta.id,
          _type: 'fileUpload',
          file: { _type: 'file', asset: { _type: 'reference', _ref: ref } },
          alt: f.meta.customMeta.caption || f.meta.customMeta.title || 'profile image',
          ...f.meta.customMeta,
        };
    }
  }

  function removeError(error) {
    const newErrors = formErrors.filter((element) => element !== error);

    setFormErrors(newErrors);
  }

  return (
    <Layout headerBackgroundImage={headerBackgroundImage} smallHeader>
      <SEO title="Student Upload Form" noindex={true} />
      <Container narrower>
        <ThemeProvider theme={theme}>
          {/*--------------------
            Step 1: Profile info
            ------------------------*/}
          <h1>PCAE Final Exhibition Upload Form</h1>
          {submitStep === 3 ? (
            <div style={{ textAlign: 'center', marginTop: '3em' }}>
              <h3>Your profile was submitted successfully.</h3>
              <div>
                <Zoom in={true}>
                  <CloudDoneIcon style={{ fontSize: 130, color: 'var(--color-neon-green)' }} />
                </Zoom>
              </div>
            </div>
          ) : (
            <div>
              <p>Welcome to the PCAE Final Exhibition! We are excited to showcase your work.</p>
              <form id="profileForm" onSubmit={handleSubmit} className={classes.form}>
                <h2>Step One: Student Profile</h2>
                <ul>
                  <li>Please complete the submission form below.</li>
                  <li>Items marked with an * are required.</li>
                  <li>Please make sure all information is entered correctly before submitting.</li>
                </ul>
                <TextField
                  required
                  ref={nameRef}
                  id="name"
                  name="name"
                  label="Preferred Name (will be displayed on the PCAE Final Exhibition website)"
                  variant="filled"
                  fullWidth
                  error={!!nameError}
                  helperText={nameError}
                  onBlur={(val) => updateTextField(val, 'name')}
                  onChange={(val) => updateTextField(val, 'name', true)}
                />
                <TextField
                  required
                  ref={idRef}
                  id="id"
                  name="id"
                  label="Student ID"
                  variant="filled"
                  fullWidth
                  error={!!idError}
                  helperText={idError}
                  onBlur={(val) => updateTextField(val, 'id')}
                  onChange={(val) => updateTextField(val, 'id', true)}
                />
                <TextField
                  type="email"
                  ref={emailRef}
                  required
                  id="email"
                  name="email"
                  label="Email"
                  variant="filled"
                  fullWidth
                  placeholder="Enter your best email address"
                  error={!!emailError}
                  helperText={emailError}
                  onBlur={(val) => updateTextField(val, 'email')}
                  onChange={(val) => updateTextField(val, 'email', true)}
                />

                {/* Modality */}
                <FormControl required fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="modality" className={classes.label}>
                    How did you attend the class?
                  </InputLabel>
                  <Select
                    native
                    onChange={chooseModality}
                    inputProps={{
                      name: 'modality',
                      id: 'modality',
                    }}
                    variant="filled"
                    fullWidth
                  >
                    {/* the values MUST match the modality array in CMS' Student schema's modality field */}
                    <option aria-label="None" value="" />
                    <option key="online" value="online">
                      Online
                    </option>
                    <option key="onsite" value="onsite">
                      Onsite
                    </option>
                    <option key="virtual" value="virtual">
                      Virtual
                    </option>
                  </Select>
                </FormControl>

                <FormControl required fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="school" className={classes.label}>
                    School
                  </InputLabel>
                  <Select
                    native
                    onChange={chooseSchool}
                    inputProps={{
                      name: 'schoolId',
                      id: 'school',
                    }}
                    variant="filled"
                    fullWidth
                  >
                    <option aria-label="None" value="" />
                    {schools.nodes
                      .sort((a, b) => a.title.localeCompare(b.title))
                      .map((school) => (
                        <option key={school._id} value={school._id}>
                          {capitalize(school.title)}
                        </option>
                      ))}
                  </Select>
                </FormControl>

                {/* Don't show major option if architecture */}
                {majors?.length > 0 && (
                  <FormControl required fullWidth className={classes.formControl}>
                    <InputLabel htmlFor="major" className={classes.label}>
                      Class
                    </InputLabel>
                    <Select
                      native
                      onChange={chooseMajor}
                      inputProps={{
                        name: 'majorId',
                        id: 'major',
                      }}
                      variant="filled"
                      fullWidth
                    >
                      <option aria-label="None" value="" />
                      {majors
                        .sort((a, b) => a.title.localeCompare(b.title))
                        .map((major) => (
                          <option key={major._id} value={major._id} selected={majors.length === 1}>
                            {capitalize(`${major.title} (${major.code})`)}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                )}

                {/*--------------------
                   Step 2: Upload work
                ------------------------*/}

                {major && (
                  <>
                    <h2>Step Two: Upload Your Work</h2>
                    <ul>
                      <li>Please submit a single image or video that represents your Final Exhibition work</li>
                      <li>Files accepted: pdf, png, jpg, mp4, mov</li>
                      <li>Images need to be at least 1600px wide</li>
                    </ul>
                    <Uploader
                      categories={[]}
                      name="portfolioImages"
                      formState={submitStep}
                      callback={updateFileUploadsCallback}
                      fileUploadStatus={fileUploadStatus}
                      label="Final Exhibition image or video here"
                      acceptedFileTypes={PORTFOLIO_FILE_TYPES}
                      school={school}
                      major={major}
                      limit={1}
                      multiple={false}
                    />
                  </>
                )}

                <p>
                  Need help? Contact us at
                  <a href="mailto:preadmissions@academyart.edu" title="email preadmissions@academyart.edu">
                    {` preadmissions@academyart.edu`}
                  </a>
                  .
                </p>
                <Button fullWidth type="submit" disabled={submitting} size="large" variant="contained" color="primary">
                  {submitting ? (
                    <>
                      <CircularProgress size={20} color={'primary'} style={{ marginRight: '10px' }} />
                      Submitting
                    </>
                  ) : (
                    'Submit profile'
                  )}
                </Button>
              </form>
            </div>
          )}
          {formErrors.length > 0 ? (
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={hasBeenSubmit} onClose={null}>
              <>
                {formErrors.map((formError, idx) => (
                  <Alert key={idx} onClose={() => removeError(formError)} severity="error">
                    {formError}
                  </Alert>
                ))}
              </>
            </Snackbar>
          ) : (
            <Modal open={modalOpen} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
              <div style={modalStyle} className={classes.paper}>
                <h2 id="simple-modal-title">Uploading files...</h2>
                <p id="simple-modal-description">Please be patient if you are uploading large files.</p>
                <Stepper activeStep={submitStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                <LinearProgress variant="buffer" color={'secondary'} value={completed} valueBuffer={buffer} />
              </div>
            </Modal>
          )}
        </ThemeProvider>
      </Container>
    </Layout>
  );
};

// To get sort syntax, use the iGraphQL tool (eg, localhost/__graphql); the tool doesn't allow multiple sort fields.
// Sanity's graphQL sort doc doesn't Work (https://www.sanity.io/docs/graphql#97427ca5bfbe)
export const query = graphql`
  query DegreesMajorSchoolsQuery {
    schools: allSanitySchool(filter: { _rawClass: { ne: null } }) {
      nodes {
        _id
        title
        class {
          _id
          title
          code
        }
      }
    }
    headerBackgroundImage: file(relativePath: { eq: "Vianca-Ruiz-AE_60_OL1-0414212-scaled.jpeg" }) {
      childImageSharp {
        fluid(maxHeight: 415, maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

export default StudentProfileForm;
